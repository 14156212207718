document.addEventListener("DOMContentLoaded", function(event) {
    const link1 = document.getElementById('img1');
    const link2 = document.getElementById('img2');
    const link3 = document.getElementById('img3');
    const img = document.getElementById('img');

    function reverseImg() {
        img.src = "/themes/default/assets/images/all_b&w.png";
    }

    link1.addEventListener('mouseover', function(){
        img.src = "/themes/default/assets/images/1_color.png";
    });

    link1.addEventListener('mouseleave', function(){
        reverseImg();
    });

    link2.addEventListener('mouseover', function(){
        img.src = "/themes/default/assets/images/2_color.png";
    });

    link2.addEventListener('mouseleave', function(){
        reverseImg();
    });

    link3.addEventListener('mouseover', function(){
        img.src = "/themes/default/assets/images/3_color.png";
    });

    link3.addEventListener('mouseleave', function(){
        reverseImg();
    });
});