
$(function() {
    // let media1199 = window.matchMedia("(max-width: 1199px)");
    let media991 = window.matchMedia("(max-width: 991px)");
    // let media767 = window.matchMedia("(max-width: 767px)");
    // let media575 = window.matchMedia("(max-width: 575px)");

    if (media991.matches) {
        $(".header__nav").addClass('padding-container');
        $(".subsite__header").find('.subsite__header-container').insertAfter('.subsite__header');
    } else {
        $(".header__nav").removeClass('padding-container');
        $(".subsite").find('.subsite__header-container').appendTo('.subsite__header');
    }

    // media575.addEventListener("change", function(media575) {
    //     if (media575.matches) {
    //         console.log('in 575');
    //     }else{
    //         console.log('out 575');
    //     }
    // });
    // media767.addEventListener("change", function(media767) {
    //     if (media767.matches) {
    //         console.log('in 767');
    //     }else{
    //         console.log('out 767');
    //     }
    // });
    media991.addEventListener("change", function(media991) {
        if (media991.matches) {
            $(".header__nav").addClass('padding-container');
            $(".subsite__header").find('.subsite__header-container').insertAfter('.subsite__header');
        }else{
            $(".header__nav").removeClass('padding-container');
            $(".subsite").find('.subsite__header-container').appendTo('.subsite__header');
        }
    });
    // media1199.addEventListener("change", function(media1199) {
    //     if (media1199.matches) {
    //         console.log('in 1199');
    //     }else{
    //         console.log('out 1199');
    //     }
    // });

});


